<template>
  <Loader :show="show_loading"/>
  <HeaderHome/>
  <FiltroEvento/>
  <div class="container-fluid body">
    <div class="container">
      <div class="row body-box">
        <div class="col-md-12">
          <div id="cphCorpo_div_organizador"><h1>Calendário de eventos - {{organizer.razao_social}}</h1>
            <p><b>Organizador</b>: {{organizer.razao_social}} - CNPJ: {{organizer.cnpj}}</p>
          </div>
          <div>

            <div class="col-md-4 event-list" v-for="(event,index) in events" :key="index">
              <div class="event" data-toggle="tooltip" data-placement="top" :title="event.name" :data-original-title="event.name">
                <v-link :href="'/evento/'+event.id+'/'+event.slug+'/'" :title="event.name">
                  <img :src="event.image" :alt="event.name">
                </v-link>
                <h3>
                  <v-link :href="'/evento/'+event.id+'/'+event.slug+'/'">{{event.name}}</v-link>
                </h3>
                <h4>
                  <v-link :href="'/evento/'+event.id+'/'+event.slug+'/'">{{event.city}}-{{event.state}}</v-link>
                </h4>
                <h5>
                  <v-link :href="'/evento/'+event.id+'/'+event.slug+'/'">
                    <span class="glyphicon glyphicon-calendar"></span> {{event.start_at}}
                  </v-link>
                </h5>
              </div>
            </div>


          </div>
          <Atalhos/>
        </div>
      </div>
    </div>
  </div>
  <Newsletter/>
  <Footer/>
  <FooterEnd/>
</template>

<script>
import {defineComponent} from "vue";
import HeaderHome from "../components/HeaderHome";
import FiltroEvento from "../components/FiltroEvento";
import Footer from "../components/Footer.vue";
import FooterEnd from "../components/FooterEnd.vue";
import Newsletter from "../components/Newsletter.vue";
import Atalhos from "@/components/Atalhos";
import VLink from "@/components/VLink";
import Organizers from "../api/Organizers";
import Loader from "../components/Loader";

export default defineComponent({
  name: "organizadorPage",
  components: {
    HeaderHome,
    Footer,
    VLink,
    FiltroEvento,
    FooterEnd,
    Newsletter,
    Atalhos,
    Loader
  },
  data() {
    return {
      slug:'',
      id:'',
      events:[],
      organizer:{},
      show_loading:true,
    };
  },
  methods: {
    fetch_events(){
      Organizers.get_events(this.id).then((request) =>{
        if(request.status == 200){
          this.organizer = request.data.organizer;
          this.events = (request.data.events).reverse();

          this.show_loading=false;
        }
      });
    }
  },
  mounted() {
    this.id =  this.$route.params.id;
    this.fetch_events();
  },
  updated() {
    if(this.id != this.$route.params.id){
      this.show_loading=true;
      this.id =  this.$route.params.id;
      this.fetch_events();
    }
  }
});
</script>

<style>
img.capa-evento-view {
  width: 100%;
}
</style>
