import axios from 'axios';

axios.defaults.headers.common['Accept'] = 'application/json' // for all requests
axios.defaults.validateStatus = false;

const api_url = process.env.VUE_APP_ROOT_API + 'api/';

export default {
    name: 'Organizers',
    get_events(id) {
        return axios.get(api_url + 'organizer/'+id );
    }
}
